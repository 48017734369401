import selectorService from '@/business/selectorService'
import trackingMapService from '@/business/trackingMapService'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import i18n from '@/i18n'
import { CustomTimeRangeTypes, TimeRanges } from '@colven/common-domain-lib/lib'
import 'leaflet/dist/leaflet.css'
import moment from 'moment'
import entepriseService from '../../../../business/enteprise.service'
import mapService from '../map.service';
//import thingApi from '@/api/thing.api'
import thingService from '../../../../business/thingService'

export default {
    name: 'TrackingMap',
    components: {
        SelectorComponent,
    },
    props: {},
    data() {
        return {
            mapId: 'trackingMap',
            trackingMapInstance: null,
            loadingSpinner: false,
            selectedThings: [],
            things: [],
            showDU: true,
            showTraces: true,
            zonePolygons: null,
            drawer: false,
            duPolygons: null,
            intervalId: null,
            traces: [],
            selectorModel: selectorService.getSelectorModel(false),
            showDriveUnits: false,
            showZones: false,
            zonesSwitch: {
                id: "showZonesSwitch",
                show: false, name: i18n.t('trackingMap.filters.showZones'),
                textTrue: '',
                textFalse: '',
                value: false
            },
            driveUnitSwitch: {
                id: "showDriveUnitsSwitch",
                show: false, name: i18n.t('trackingMap.filters.showDriveUnits'),
                textTrue: '',
                textFalse: '',
                value: false
            },
            equipmentSwitch: {
                id: "showEquipmentSwitch",
                show: false, name: "Filtros",
                textTrue: 'Equipos',
                textFalse: 'Frente de trabajo',
                value: false
            },
            workFrontFilter: {
                id: 'workFront',
                name: i18n.t('headers.workFront'),
                show: false,
                disabled: false,
                showDialog: false,
                singleSelect: false,
                data: [],
                selectedData: [],
                selectAction: undefined,
                hide: true
            },
            equipmentFilter: {
                id: 'equipment',
                name: "Equipos",
                show: false,
                disabled: false,
                showDialog: false,
                singleSelect: false,
                data: [],
                selectedData: [],
                selectAction: undefined,
                hide: true
            },
        }
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
    async mounted() {
        this.loadingSpinner = true;
        const config = {
            idElement: this.mapId,
            centerPosition: [-24.475390108274823, -65.03700482239184],
            zoom: 8,
            preferCanvas: false,
            zoomControl: true
        }
        this.initSelector();
        this.trackingMapInstance = mapService.createMap(config);
        await this.initMap()
        this.loadingSpinner = false;
    },
    computed: {},
    methods: {
        initSelector() {
            const date = new Date();
            let currentDateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            this.selectorModel.sinceDate = currentDateString;
            this.selectorModel.filters.push(this.workFrontFilter, this.equipmentFilter)
            this.selectorModel.filterSwitch = this.equipmentSwitch;
            this.selectorModel.filterSwitch.value = true
            this.selectorModel.showGroupEquipmentFilter = true
            this.selectorModel.showTimeFormatSelector = false
            this.selectorModel.hideEquipmentConfigSwitch = true
            this.selectorModel.hideEquipmentConfigSwitch = true
            this.selectorModel.selectedCount = 0;
            this.selectorModel.selectionLimit = 60;
            this.selectorModel.selectedDateAndTimeRange = TimeRanges.CUSTOM
            this.selectorModel.singleDaySelect = true
            this.selectorModel.selectedDateAndTimeRangeCustomType = CustomTimeRangeTypes.DATE
            this.selectorModel.switches.push(this.equipmentSwitch, this.zonesSwitch, this.driveUnitSwitch)
            this.selectorModel.disableGetReportButton = true;
            this.selectorModel.forceReportSwitch = true;
            this.selectorModel.showCount = true;
            this.selectorModel.disableDateAutoReport = true;
            this.selectorModel.autoReportCommentLabel = i18n.t('trackingMap.filters.autoReportCommentLabel');
        },
        selector() {
            this.drawer = !this.drawer
        },
        async initMap() {
            await this.getThings();
        },
        async getZones() {
            const zonas = await trackingMapService.getZonesForMap()
            if (zonas.length > 0) {
                const polys = mapService.addPolygons(this.trackingMapInstance, zonas)
                this.zonePolygons = polys;
            }
        },
        async getDriveUnits() {
            const driveUnits = await trackingMapService.getDriveUnitsForMap()
            if (driveUnits.length > 0) {
                const polys = mapService.addPolygons(this.trackingMapInstance, driveUnits);
                this.duPolygons = polys;
            }

        },
        async getThings() {
            const things = await trackingMapService.getThingsForMapSelector();
           
            await thingService.getNestedWorkFrontsOrdered().then(workFronts=>{
                this.workFrontFilter.data.cleanAndUpdate(workFronts)
            }

            )
            
            this.transformAndUpdateThings(things);
        },
        transformAndUpdateThings(things) {
            const thingsByTypeForSelector = [];
            things.forEach(t => {
                const found = thingsByTypeForSelector.find(type => type.id === t.typeId);
                if (found) {
                    found.children.push({ ...t, nodeType: "THING" });
                } else {
                    thingsByTypeForSelector.push({
                        id: t.typeId ? t.typeId : null,
                        name: t.type ? t.type : 'Sin calificar',
                        nodeType: "GROUP",
                        children: [{ ...t, nodeType: "THING" }]
                    })
                }
            });
            this.selectorModel.groupEquipmentFilter.data.cleanAndUpdate(thingsByTypeForSelector);
        },
        async getTraces(ids, selectedDate, filterTracks, autoReport) {
            if (filterTracks) { // Auto reporte
                if (ids.length && selectedDate) {
                    const traces = await trackingMapService.getSelectedTraces(ids, selectedDate, true, autoReport);
                    if (traces && traces.length > 0) {
                        for (const trace of traces) {
                            if (trace.length > 0) {
                                for (const thing of trace) {
                                    if (thing.positions.length > 0) {
                                        const newTraces = [];
                                        const trace = this.traces.find(trace => trace.id === thing.id);
                                        if (trace) {
                                            mapService.addLinesToExistingTrace(trace.trace, trace.marker, thing.positions);
                                        } else {
                                            newTraces.push(thing)
                                        }
                                        if (newTraces.length > 0) {
                                            const polylines = mapService.addLines(this.trackingMapInstance, newTraces);
                                            for (const polyline of polylines) {
                                                this.traces.push(polyline);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                this.traces.forEach(set => {
                    if (set.trace) set.trace.remove();
                    if (set.marker) set.marker.remove();
                })
                if (ids.length && selectedDate) {
                    const traces = await trackingMapService.getSelectedTraces(ids, selectedDate, false, autoReport);
                    if (traces && traces.length > 0) {
                        let finalTraces = [];
                        for (const trace of traces) {
                            if (trace.length > 0) {
                                finalTraces = finalTraces.concat(trace);
                            }
                        }
                        const polys = await mapService.addLines(this.trackingMapInstance, finalTraces);
                        if (polys) this.traces.cleanAndUpdate(polys);
                    }
                }
            }
        },
        async getReport(event) {
            const ids = [];
            try {
                this.selector();
                this.loadingSpinner = true;
                let values = null
                if(this.equipmentSwitch.value){
                values = event.groupEquipmentFilterSelected;
                }else{
                   values = this.workFrontFilter.selectedData
                }
                // obtiene las ids de los equipos seleccionados
                values.forEach(groupNode => {
                    groupNode.children.forEach(thing => ids.push(thing.id))
                })

                if (this.intervalId) clearInterval(this.intervalId);
                // Auto reporte
                let from = null;
                if (!event.autoReport) {
                    from = moment(event.sinceDate, 'YYYY-MM-DD').unix();
                } else {
                    from = moment().unix();
                }
                //FIXME: Sacar esto de acá y ponerlo en un boton sobre el mapa
                // Activa o desactiva las zonas y las unidades de manejo segun lo seleccionado
                if (this.zonesSwitch.value) {
                    if (this.zonesSwitch.value != this.showZones) {
                        if (!this.zonePolygons) {
                            await this.getZones();
                        } else {
                            mapService.insertPolygons(this.trackingMapInstance, this.zonePolygons || []);
                        }
                        this.showZones = true;
                    }
                } else {
                    if (this.zonesSwitch.value != this.showZones) {
                        this.showZones = false;
                        mapService.removePolygons(this.zonePolygons);
                    }
                }

                if (this.driveUnitSwitch.value) {
                    if (this.driveUnitSwitch.value != this.showDriveUnits) {
                        if (!this.duPolygons) {
                            await this.getDriveUnits();
                        } else {
                            mapService.insertPolygons(this.trackingMapInstance, this.duPolygons || []);
                        }
                        this.showDriveUnits = true;
                    }
                } else {
                    if (this.driveUnitSwitch.value != this.showDriveUnits) {
                        this.showDriveUnits = false;
                        mapService.removePolygons(this.duPolygons);
                    }
                }
                await this.getTraces(ids, from, false, event.autoReport)
            } finally {
                this.loadingSpinner = false;
            }
            if (event.autoReport) {
                let previousMoment = moment();
                const timespanUTC = entepriseService.getEnterpriseTimeSpanUTC();
                this.intervalId = setInterval(async () => {
                    let filterTracks = true;
                    this.loadingSpinner = true;
                    let currentMoment = moment();
                    try {
                        const timespanMoment = moment().utc().set({ hour: timespanUTC, minute: 0, second: 0, millisecond: 0 });
                        let currentDate = moment().add(event.autoReportMinutes * -1, "minutes").unix();
                        if (
                            (currentMoment.isAfter(timespanMoment) && previousMoment.isBefore(timespanMoment))
                            || currentMoment.isSame(timespanMoment) || previousMoment.isSame(timespanMoment)
                        ) {
                            currentDate = timespanMoment.unix();
                            filterTracks = false;
                        }
                        await this.getTraces(ids, currentDate, filterTracks, event.autoReport);
                    } finally {
                        this.loadingSpinner = false;
                    }
                    previousMoment = currentMoment.add(1, "second");
                }, event.autoReportMinutes * 60 * 1000);
            }
        },
        getFirstAndLastDate(date) {
            const firstDate = moment(date, 'YYYY-MM-DD').toDate();
            firstDate.setHours(0);
            firstDate.setMinutes(0);
            firstDate.setSeconds(0);
            firstDate.setMilliseconds(0);

            const secondDate = moment(date, 'YYYY-MM-DD').toDate();
            secondDate.setHours(23);
            secondDate.setMinutes(59);
            secondDate.setSeconds(59);
            secondDate.setMilliseconds(0);

            return [firstDate.getTime(), secondDate.getTime()];
        }
    },
    watch:{
        selectorModel: {
            handler: async function () {

                if(!this.equipmentSwitch.value){
                    this.workFrontFilter.hide = false;
                    this.equipmentFilter.hide = true
                    
                }else{
                    this.workFrontFilter.hide = true
                    this.equipmentFilter.hide = false
                }
               
            },
            deep: true
        }
    }
}
