import service from '@/middleware';


const TracesApi = {
    async getTraces(ids, selectedDate, filterTracks, autoReport) {
        const response = await service.http.post('/api/traces/get-all', { ids, selectedDate, filterTracks, autoReport });
        return response.data;
    }
}

export default TracesApi