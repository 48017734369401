
import leaflet from 'leaflet';
import { MARKER_ICONS } from '@/assets/markers';
import { traceColors } from '@/constants/constants';
import { mapCurrentStateService } from '@/business/mapCurrentStateService'
import { iconSkeleton } from '@/assets/icon-skeleton.';

class MapService {


    /**
     * config = {.
     * idElement: string
     * centerPosition: [number, number],
     * zoom: number
     * preferCanvas: bool
     * }
     */

    createMap(config) {
        // inicializa el mapa en el div con el id proporcionado, con centro y zoom proporcionado en las coordenadas de la configuración 
        const map = leaflet.map(config.idElement, { ...config }).setView(config.centerPosition, config.zoom);
        leaflet.tileLayer("https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga", { maxZoom: 19 }).addTo(map);
        leaflet.control.zoom({ position: 'bottomleft' }).addTo(map);
        return map;
    }

    addPolygon(mapInstance, polygonPoints, config) {
        const polygon = leaflet.polygon(polygonPoints, config)
        polygon.bindTooltip(config.name, { direction: "auto" });
        polygon.addTo(mapInstance)
        return polygon;
    }

    addPolygons(mapInstance, polygons) {
        const result = [];
        const bounds = []
        for (const poly of polygons) {
            let polygon = this.addPolygon(mapInstance, poly.coordinates, { ...poly.properties });
            bounds.push(polygon.getBounds());
            result.push(polygon);
        }

        mapInstance.fitBounds(bounds)
        return result;
    }

    addMarker(mapInstance, latLong, icon) {
        const marker = leaflet.marker(latLong, { icon }).addTo(mapInstance);
        return marker;
    }

    deleteMarker(mapInstance, marker) {
        if (marker) {
            mapInstance.removeLayer(marker)
        }
    }

    removePolygons(polygons) {
        for (const poly of polygons) {
            poly.remove();
        }
    }

    insertPolygons(mapInstance, polygons) {
        for (const poly of polygons) {
            poly.addTo(mapInstance);
        }
    }

    async addLines(mapInstance, things) {
        if (things && things.length > 0) {
            const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))
            const traces = [];
            const bounds = [];
            for (const [index, thing] of things.entries()) {
                const pointList = [];
                if (thing.positions && thing.positions.length > 0) {
                    for (const latLong of thing.positions) {
                        pointList.push(new leaflet.LatLng(latLong[1], latLong[0]));
                    }
                    const trace = new leaflet.polyline(pointList, {
                        color: traceColors[index],
                        weight: 5,
                        opacity: 0.5,
                        smoothFactor: 1
                    });
                    trace.addTo(mapInstance);
                    bounds.push(trace.getBounds());

                    const type = thing.type;
                    // const enterpriseId = thing.enterpriseId;
                    let iconFile;

                    // Iconos custom para las empresas que tengan
                    if (thing.iconId) {
                        const custom = await mapCurrentStateService.getCustomIcon(thing.iconId);
                        if (custom && custom.images && custom.images.east) iconFile = this.generateIconData(custom.images.east);
                    }
                    if (!iconFile && type && enterpriseId) {
                        let icon = null
                        const custom = await mapCurrentStateService.getCustomIcon(`${enterpriseId}-${type}`);

                        if (custom && custom.images && custom.images.east) {
                            icon = this.generateIconData(custom.images.east)
                        }

                        if (icon) iconFile = icon;
                    }
                    if (!iconFile && type) {
                        const icon = MARKER_ICONS[type];

                        iconFile = this.generateIconData(icon);
                    }
                    const icon = iconFile ? iconFile : leaflet.icon({ iconUrl: leaflet.Icon.Default, iconSize: [40, 40], iconAnchor: [20, 35] });
                    const marker = leaflet.marker(pointList[pointList.length - 1], { icon }).bindTooltip(thing.name, { direction: 'bottom' }).addTo(mapInstance);
                    traces.push({ trace, marker, id: thing.id })

                }
            }
            mapInstance.fitBounds(bounds);
            return traces;
        }
    }

    addLinesToExistingTrace(trace, marker, points) {
        if (trace && points && points.length > 0) {
            for (const point of points) {
                trace.addLatLng([point[1], point[0]]);
            }
            const lastLatLng = points[points.length - 1];
            const newLatLng = new leaflet.LatLng(lastLatLng[1], lastLatLng[0]);
            marker.setLatLng(newLatLng)
        }
    }

    addTooltip(element, tooltip, config) {
        if (element && tooltip && config) {
            element.bindTooltip(tooltip, config)
        }
    }

    generateIconData(b64Icon) {
        // Este objeto contiene datos que son reemplazados en el template iconSkeleton
        const svgParametersMapped = {
            SVG_ID: `ID-${Math.floor(Math.random() * 10000000)}`,
            BACKGROUND_COLOR: 'transparent',
            STATE_STYLE: `visibility: visible !important; fill: "#1974D2" !important;`,
            STOPPED_STYLE: 'visibility: hidden;',
            INNER_ICON: b64Icon
        }

        // Establece como vacios los estilos de las direcciones que no se utilizan (en el caso del mapa de recorrido solo se utiliza la direccion este)
        const directions = ['north', 'south', 'east', 'west', 'northeast', 'northwest', 'southeast', 'southwest']
        directions.forEach(dir => {
            svgParametersMapped[dir] = ''
        })

        svgParametersMapped["east"] = `visibility: visible !important; !important;`

        // Crea el svg reemplazando los valores del template por los valores generados arriba
        const svg = leaflet.Util.template(iconSkeleton, svgParametersMapped)

        const icon = leaflet.divIcon({
            className: 'leaflet-data-marker',
            html: `<span>${svg}</span>`,
            iconAnchor: [20, 35],
            iconSize: [90, 90],
            popupAnchor: [0, -28]
        })

        return icon;
    }
}

export default new MapService();