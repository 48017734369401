import ZonesApi from "@/api/zones.api"
import driveUnitApi from "@/api/drive-unit.api";
import thingApi from "@/api/thing.api";
import TracesApi from "@/api/traces.api";


const getZonesForMap = async () => {
    const zonas = await ZonesApi.getZonesByEnterprise();
    return processZones(zonas);
}

const processZones = (zonas) => {
    const procesedZones = zonas.map(zona => {
        if (zona.geometry && zona.geometry.coordinates && zona.geometry.coordinates.length > 0) {
            const coordinates = zona.geometry.coordinates[0];
            const reversedCoordinates = coordinates.map(point => [point[1], point[0]])
            return { coordinates: reversedCoordinates, properties: { name: zona.properties.name, color: zona.properties.color } }
        }
    })
    return procesedZones.length > 0 ? procesedZones : [];
}

const getDriveUnitsForMap = async () => {
    const driveUnits = await driveUnitApi.getByEnterprise();
    return processDriveUnits(driveUnits);
}

const processDriveUnits = (driveUnits) => {
    const procesedDriveUnits = Object.values(driveUnits).map(du => {
        if (du.geometry && du.geometry.coordinates && du.geometry.coordinates.length > 0) {
            const coordinates = du.geometry.coordinates[0];
            const reversedCoordinates = coordinates.map(point => [point[1], point[0]]);
            return { coordinates: reversedCoordinates, properties: { name: du.properties.Name, color: '#26ff26' } }
        }
    });
    return procesedDriveUnits.length > 0 ? procesedDriveUnits : [];
}

const getThingsForMapSelector = async () => {
    const response = await thingApi.getAllNestedByEnterprise();
    const data = response.data
    if (data[0] && data[0].children) return data[0].children;
}


const getSelectedTraces = async (ids, selectedDate, filterTracks, autoReport) => {
    const result = await TracesApi.getTraces(ids, selectedDate, filterTracks, autoReport);
    return result
}

export default { getZonesForMap, getDriveUnitsForMap, getThingsForMapSelector, getSelectedTraces }